.title {
  margin: 0;
  font-weight: 700;
  font-size: 2.5rem;
  text-align: center;
  color: #000;
}

@media (min-width: 540px) {
  .title {
    font-size: 3.5rem;
  }
}
