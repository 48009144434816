.work {
  display: block;
}

.work:not(:last-child) {
  margin-bottom: 3rem;
}

.work__images {
  position: relative;
  flex: 0 0 auto;
  padding-bottom: 100%;
  width: 100%;
  height: 0;
  box-shadow: 0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.25);
  background-color: #fafafa;
}

.work__images--empty {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.work__images--empty::before {
  content: 'No image';
  position: absolute;
  left: 50%;
  top: 50%;
  font-weight: 700;
  font-size: 3rem;
  color: #ccc;
  transform: translate(-50%, -50%);
}

.work__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.work__image--active {
  z-index: 2;
  opacity: 1;
  visibility: visible;
}

.work__container {
  margin-top: 2rem;
}

.work__name {
  overflow: hidden;
  margin: 0;
  font-size: 1.5rem;
  text-overflow: ellipsis;
}

.work__name a {
  text-decoration: none;
  color: #000;
  transition: color 0.3s;
}

.work__name a:hover {
  color: #ea4335;
}

.work__text {
  overflow: hidden;
  margin-top: 1rem;
  font-size: 1rem;
  text-overflow: ellipsis;
}

.work__links {
  display: flex;
  list-style-type: none;
  margin: 1rem 0 0;
  padding: 0;
}

.work__links li:not(:last-child) {
  margin-right: 0.75rem;
}

.work__link {
  display: block;
  transition: transform 0.3s;
  transform: translateZ(0);
}

.work__link:hover {
  transform: scale(1.25) translateZ(0);
}

.work__links svg {
  width: 2rem;
  height: 2rem;
}

@media (min-width: 384px) {
  .work__images {
    margin: 0 auto;
    padding-bottom: 0;
    width: 15rem;
    height: 15rem;
  }

  .work__images--empty::before {
    position: static;
    transform: none;
  }
}

@media (min-width: 540px) {
  .work {
    display: flex;
  }

  .work__images {
    margin: 0;
    width: 10rem;
    height: 10rem;
  }

  .work__images--empty::before {
    font-size: 2rem;
  }

  .work__container {
    flex: 1 1 auto;
    overflow: hidden;
    margin-top: 0;
    padding-left: 2rem;
  }

  .work__name {
    font-size: 2rem;
  }

  .work__text {
    font-size: 1.5rem;
  }
}

@media (min-width: 640px) {
  .work__images {
    width: 15rem;
    height: 15rem;
  }

  .work__images--empty::before {
    font-size: 2.5rem;
  }
}

@media (min-width: 768px) {
  .work__images--empty::before {
    font-size: 3rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .work__link,
  .work__link:hover {
    transform: none;
  }
}
